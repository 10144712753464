"use client"; // Error components must be a Client Component
import { type FC, type ReactNode } from "react";
import { LuHelpCircle } from "react-icons/lu";
import { Text } from "~/modules/common/components/atoms/Text";

interface ErrorPageProps {
  children?: ReactNode;
}

export const ErrorPage: FC<ErrorPageProps> = ({ children }) => {
  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center gap-6">
      <LuHelpCircle className="text-green-1 opacity-90" size={128} />
      <Text size="h2" className="text-green-1">
        500
      </Text>
      <Text>{"Une erreur est survenue lors du chargement de la page"}</Text>
      {children}
    </div>
  );
};
